import { Container } from "pixi.js";
export class AnimationController {
    constructor(animations) {
        this.view = new Container();
        this.animations = Object.assign({}, animations);
    }
    setAnimation(id) {
        const next = this.animations[id];
        if (next === this.selected)
            return this;
        if (this.selected) {
            this.selected.gotoAndStop(0);
            this.view.removeChild(this.selected);
        }
        this.selected = this.animations[id];
        this.view.addChild(this.selected);
        return this;
    }
    play() {
        this.selected.play();
        return this;
    }
    stop() {
        this.selected.stop();
        return this;
    }
    setFrame(frame) {
        this.selected.gotoAndStop(frame);
        return this;
    }
}
