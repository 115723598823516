var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Platform } from "@components/objects/Platform/Platform";
import { Player } from "@components/objects/Player/player";
import { PhysicsEngine } from "@components/physics/physics";
import { Tilemap } from "@pixi/tilemap";
import { Container } from "pixi.js";
import spike from '../../assets/audio/spike.mp3';
export class Scene {
    constructor(data, spritesheet) {
        this.view = new Container();
        this.tilemap = new Tilemap(spritesheet.textureSource);
        this.cols = data.cols;
        this.rows = data.rows;
        this.physicsEngine = new PhysicsEngine();
        this.render(data, spritesheet);
        this.view.addChild(this.tilemap);
        this.buildPlatforms(data);
        this.playerStart = data.player;
        this.init(data);
    }
    init(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.player = yield Player.Create(this.physicsEngine);
            this.player.collisionBody.onCollision((o, _) => {
                var _a;
                const pcLeftRight = ((this.player.collisionBody.maxY() > o.maxY() ? o.maxY() : this.player.collisionBody.maxY())
                    - (this.player.collisionBody.minY() < o.minY() ? o.minY() : this.player.collisionBody.minY())) / (this.player.collisionBody.maxY() - this.player.collisionBody.minY());
                if (o.type === 'spike' && pcLeftRight > 0.4) {
                    const spikeaudio = new Audio(spike);
                    spikeaudio.play();
                    this.player.lastDeath = 20;
                    this.player.view.x = data.player.x * 16;
                    this.player.view.y = data.player.y * 16;
                    return;
                }
                else if (o.type === 'exit') {
                    (_a = this.nextScene) === null || _a === void 0 ? void 0 : _a.call(this);
                }
            });
            this.reset();
            this.view.addChild(this.player.view);
        });
    }
    reset() {
        this.player.view.x = this.playerStart.x * 16;
        this.player.view.y = this.playerStart.y * 16;
    }
    render(data, spritesheet) {
        var _a;
        const ids = ["blank", "grass_top", "solid", "spike", "fence_left",
            "fence_mid", "fence_right", "flower1", "flower2", "exit",
            "left_wall", "top_wall", "right_wall", "rock", "blank_dot"];
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                let id = data.tiles[i * this.cols + j];
                this.tilemap.tile(spritesheet.textures[(_a = ids[id]) !== null && _a !== void 0 ? _a : "flower1"], j * 16, i * 16);
            }
        }
    }
    buildPlatforms(data) {
        if (!data.collisions)
            return;
        for (const { create, boxes } of data.collisions) {
            for (const { x, y, w, h } of boxes) {
                this.view.addChild(create(this.physicsEngine, { x: x * 16, y: y * 16, w: w * 16, h: h * 16 }).view);
            }
        }
    }
    buildPlatform(x, y, w, h) {
        const platform = new Platform(this.physicsEngine, x, y, w, h);
        this.view.addChild(platform.view);
    }
    update(deltaTime) {
        this.physicsEngine.update(deltaTime);
        this.player.update(deltaTime);
    }
}
