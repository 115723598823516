import { Container, Text } from "pixi.js";
export class SceneManager {
    constructor() {
        this.view = new Container();
        this.currentScene = -1;
        this.scenes = [];
        this.ids = {};
    }
    appendScene(id, scene) {
        var _a;
        if (((_a = this.ids) === null || _a === void 0 ? void 0 : _a[id]) !== undefined)
            return false;
        this.ids[id] = this.scenes.length;
        this.scenes.push(scene);
        scene.nextScene = () => this.nextScene();
        if (this.currentScene === -1) {
            this.currentScene = 0;
            this.view.addChild(this.scenes[this.currentScene].view);
        }
        if (id === "level_0") {
            const Title = new Text({
                text: 'Pogo Parkour!',
                style: {
                    fontSize: 350,
                    align: 'center',
                },
            });
            const controls = new Text({
                text: 'Use Left and Right to Move\nSpace to jump\nDown Arrow Key to Pogo on Spikes!',
                style: {
                    fontSize: 150,
                    align: 'left',
                },
            });
            Title.scale.set(0.1);
            Title.x = scene.view.width / 6;
            Title.y = scene.view.height / 8;
            controls.x = scene.view.width / 6;
            controls.y = scene.view.height / 3;
            controls.scale.set(0.1);
            scene.view.addChild(Title, controls);
        }
        return true;
    }
    attach() {
        this.scenes[this.currentScene].reset();
        this.view.addChild(this.scenes[this.currentScene].view);
    }
    detatch() {
        this.view.removeChild(this.scenes[this.currentScene].view);
    }
    setScene(id) {
        const index = this.ids[id];
        if (index === undefined)
            return;
        this.detatch();
        this.currentScene = index;
        this.attach();
    }
    nextScene() {
        if (this.scenes.length === 0)
            return;
        this.detatch();
        this.currentScene++;
        this.currentScene %= this.scenes.length;
        this.attach();
    }
    prevScene() {
        if (this.scenes.length === 0)
            return;
        this.detatch();
        this.currentScene += this.scenes.length - 1;
        this.currentScene %= this.scenes.length;
        this.attach();
    }
    getScene() {
        if (this.currentScene < 0)
            return undefined;
        return this.scenes[this.currentScene];
    }
    update(deltaTime) {
        var _a;
        (_a = this.getScene()) === null || _a === void 0 ? void 0 : _a.update(deltaTime);
    }
}
