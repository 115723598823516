import { CollisionBody } from "@components/physics/collisionbody";
import { Container } from "pixi.js";
export class Platform {
    constructor(engine, x, y, w, h) {
        this.view = new Container();
        this.view.position.set(x, y);
        this.view.width = w;
        this.view.height = h;
        this.view.addChild(new CollisionBody(engine, 0, 0, w, h, 'platform').view);
    }
}
