var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { controller } from "@components/controller/Controller";
import { Application, Assets, Spritesheet, Texture } from "pixi.js";
import { atlasData } from "./assets/atlas";
import { Scenes } from "@components/scenes/Scenes";
import { SceneManager } from "@components/scenes/SceneManager";
document.body.style.margin = '0'; // Removes margin around page
document.body.style.overflow = 'hidden'; // Fix scrolling
(() => __awaiter(void 0, void 0, void 0, function* () {
    // Create a PixiJS application.
    const app = new Application();
    // Intialize the application.
    yield app.init({ background: "#1099bb", resizeTo: window });
    // Then adding the application's canvas to the DOM body.
    document.body.appendChild(app.canvas);
    Assets.add({ alias: 'characters', src: atlasData.characters.meta.image });
    Assets.add({ alias: 'tiles', src: atlasData.tiles.meta.image });
    const textures = yield Assets.load([{ alias: 'characters' }, { alias: 'tiles' }]);
    textures.characters.source.scaleMode = 'nearest';
    textures.tiles.source.scaleMode = 'nearest';
    const tileSpriteSheet = new Spritesheet(Texture.from(atlasData.tiles.meta.image), atlasData.tiles);
    yield tileSpriteSheet.parse();
    const scenes = Scenes(tileSpriteSheet);
    const sceneManager = new SceneManager();
    for (const { id, scene } of scenes) {
        sceneManager.appendScene(id, scene);
    }
    app.stage.addChild(sceneManager.view);
    controller.attachKeyDownCallback(k => {
        if (k === "next") {
            sceneManager.nextScene();
        }
    });
    app.ticker.add((ticker) => {
        // Resize the scene
        const scene = sceneManager.getScene();
        const scaleX = app.renderer.width / (scene.cols * 16);
        const scaleY = app.renderer.height / (scene.rows * 16);
        const scale = Math.min(scaleX, scaleY);
        scene.view.scale = scale;
        scene.view.x = (app.renderer.width - (scene.cols * 16 * scale)) / 2;
        scene.view.y = (app.renderer.height - (scene.rows * 16 * scale)) / 2;
        // Apply updates
        const deltaTime = ticker.deltaTime;
        sceneManager.update(deltaTime);
    });
}))();
