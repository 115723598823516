import { Graphics } from "pixi.js";
export class Rectangle extends Graphics {
    constructor(x, y, w, h, c) {
        super();
        this.beginPath();
        this.setStrokeStyle(c !== null && c !== void 0 ? c : "#FF000000");
        this.rect(x, y, w, h);
        this.stroke();
        this.closePath();
    }
}
